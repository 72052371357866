import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import * as RadioButtonStories from '@stories/radio-button.stories.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Example = makeShortcode("Example");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
    
    <ul>
    <li><a className="ln-c-link" href="#how-to-use-radio-buttons">How to use radio buttons</a></li>
    <li><a className="ln-c-link" href="#types-of-radio-buttons">Types of radio buttons</a></li>
    <li><a className="ln-c-link" href="#copy-guidelines">Copy guidelines</a></li>
    </ul>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-radio-buttons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-radio-buttons",
        "aria-label": "how to use radio buttons permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use radio buttons`}</h2>
    <p>{`Use radio buttons when users must make a single selection from a group of mutually exclusive choices.`}</p>
    <p>{`When using radio buttons:`}</p>
    <ul>
      <li parentName="ul">{`Make each radio button dependent on each other, so selecting one radio button should change the status of another one in the list`}</li>
      <li parentName="ul">{`List your items in a clear way, whether it’s alphabetical, numerical, time-based or some other logical order`}</li>
      <li parentName="ul">{`Have a default option selected wherever possible`}</li>
      <li parentName="ul">{`Always have a unique visible label attached to each radio button`}</li>
      <li parentName="ul">{`Visually position your label to the right of the radio button`}</li>
      <li parentName="ul">{`Programmatically connect each label to its corresponding radio button`}</li>
    </ul>
    <p>{`If users need to choose more than one option, use a `}<a parentName="p" {...{
        "href": "/components/checkbox"
      }}>{`checkbox`}</a>{` instead.`}</p>
    <p>{`If users need to turn something on or off, use a `}<a parentName="p" {...{
        "href": "/components/switch"
      }}>{`switch`}</a>{` instead.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "types-of-radio-buttons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-radio-buttons",
        "aria-label": "types of radio buttons permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of radio buttons`}</h2>
    <h3>{`Stacked`}</h3>
    <p>{`Use stacked radio buttons if there are more than 3 options for the user to choose from.`}</p>
    <Example stories={RadioButtonStories} component={'Stacked'} stretch mdxType="Example" />
    <h3>{`Inline`}</h3>
    <p>{`Use inline radio buttons if there are fewer than 3 options for the user to choose from and the radio button labels are short.`}</p>
    <Example stories={RadioButtonStories} component={'Inline'} stretch mdxType="Example" />
    <h3>{`Outlined`}</h3>
    <p>{`Use outlined radio buttons to give the options more visual impact and distinction.`}</p>
    <Example stories={RadioButtonStories} component={'Outlined'} stretch mdxType="Example" />
    <Example stories={RadioButtonStories} component={'OutlinedInline'} stretch mdxType="Example" />
    <h3>{`With error message`}</h3>
    <p>{`Make sure the error message is announced to screen reader users when it is triggered.`}</p>
    <Example stories={RadioButtonStories} component={'WithErrorMessage'} stretch mdxType="Example" />
    <h3>{`Standalone`}</h3>
    <p>{`Standalone radio buttons let you remove text for more flexibility with the position of the radio button. Make sure that context is provided to the user by the surrounding content of the radio button and that the radio button has an accessible name.`}</p>
    <Example stories={RadioButtonStories} component={'Standalone'} inline mdxType="Example" />
    <hr></hr>
    <h2 {...{
      "id": "copy-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#copy-guidelines",
        "aria-label": "copy guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Copy guidelines`}</h2>
    <p>{`When using radio buttons:`}</p>
    <ul>
      <li parentName="ul">{`They should be introduced by a clear heading`}</li>
      <li parentName="ul">{`Start your radio button labels with a capital letter`}</li>
      <li parentName="ul">{`Don't use commas or any punctuation at the end of each label`}</li>
      <li parentName="ul">{`Don't use more than three words per label`}</li>
    </ul>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using radio buttons or give you a
  helping hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      